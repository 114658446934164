<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M.75 1.75h18a.75.75 0 1 0 0-1.5h-18a.75.75 0 0 0 0 1.5zM5.75 7.75h13a.75.75 0 1 0 0-1.5h-13a.75.75 0 0 0 0 1.5zM.75 13.75h18a.75.75 0 1 0 0-1.5h-18a.75.75 0 1 0 0 1.5z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
